import React, { useState } from 'react';
import axios from 'axios';
import './Liturgia.css';  

const Liturgia = () => {
  const [numero, setNumero] = useState('');
  const [horario, setHorario] = useState('06:00');  
  const [nome, setNome] = useState('');  
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    if (e.target.name === 'numero') {
      setNumero(e.target.value);
    } else if (e.target.name === 'horario') {
      setHorario(e.target.value);
    } else if (e.target.name === 'nome') {
      setNome(e.target.value);  
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica se o número é válido (13 dígitos, incluindo DDD e código do país)
    if (!numero || numero.length !== 11 || !/^\d+$/.test(numero)) {
      setStatus('Número inválido. Por favor, insira um número válido com 11 dígitos (ex: 11984782523).');
      return;
    }

    if (!nome) {
      setStatus('Por favor, insira seu nome.');
      return;
    }

    const timestamp = new Date().toISOString();  // Gera o timestamp no formato ISO

    try {
      // Faz a requisição POST para adicionar o número, nome, horário e timestamp no backend
      const response = await axios.post('https://liturgia.onrender.com/adicionar-contato', { numero, horario, nome, timestamp });

      if (response.status === 200) {
        setStatus('Cadastro realizado com sucesso! Você começará a receber o Evangelho do Dia no seu WhatsApp.');
        setNumero(''); // Limpa o campo após o cadastro
        setHorario('06:00'); // Reseta o horário para o valor padrão
        setNome(''); // Limpa o campo de nome
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setStatus('Este número já está cadastrado. Obrigado por acompanhar a palavra de Deus!');
      } else {
        setStatus('Erro ao cadastrar o número. Por favor, tente novamente mais tarde.');
      }
    }
  };

  return (
    <div className="cadastro-container">
      <h2>Receba diariamente a Palavra de Deus no seu WhatsApp</h2>
      
      <p className="intro-text">
      Cadastre-se e receba diariamente o Evangelho do Dia no seu WhatsApp. Escolha o horário que mais combina com a sua rotina:
      </p>
      <form onSubmit={handleSubmit} className="form-cadastro">
        <input
          type="text"
          name="nome"
          value={nome}
          onChange={handleChange}
          placeholder="Digite seu nome"
          className="input-cadastro"
        />
        <input
          type="text"
          name="numero"
          value={numero}
          onChange={handleChange}
          placeholder="Celular "
          className="input-cadastro"
        />
        <select
          name="horario"
          value={horario}
          onChange={handleChange}
          className="input-cadastro"
        >
          
          <option value="06:00">06:00</option>
          <option value="09:00">09:00</option>
          <option value="12:00">12:00</option>
          <option value="15:00">15:00</option>
          <option value="18:00">18:00</option>
          <option value="21:00">21:00</option>
        </select>
        <button type="submit" className="btn-cadastro">Quero Receber o Evangelho</button>
      </form>
      {status && (
        <p className={`status-message ${status.includes('sucesso') ? 'success' : 'error'}`}>{status}</p>
      )}
    </div>
  );
};

export default Liturgia;