import React, { useEffect } from 'react';
import Slider from 'react-slick';
import './index.css';
import YouTubeEmbed from './YouTubeEmbed';
import { FaGlobe, FaYoutube, FaShoppingCart, FaInstagram, FaFacebook, FaTiktok, FaSpotify } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from 'react-share';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Liturgia from './Liturgia';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAI2x2V031SF9Bywm9GlJc7-QWaUfKBkwc",
  authDomain: "freigilson-51dd0.firebaseapp.com",
  projectId: "freigilson-51dd0",
  storageBucket: "freigilson-51dd0.appspot.com",
  messagingSenderId: "662878704244",
  appId: "1:662878704244:web:59e68d14be69089c27b721",
  measurementId: "G-YJNK4MGNWH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  const channelId = 'UCbh6_TmFnAJLI56aAQeD3qw';  // Exemplo de canal (Google Developers)
  const apiKey = 'AIzaSyCu03ZcQPZ2KKaeUlwu3hpfgS9l22e1Zuw';  // Sua chave da API

  useEffect(() => {
    const menuToggle = document.getElementById("menu-toggle");
    const navMenu = document.getElementById("nav-menu");

    if (menuToggle && navMenu) {
      const toggleMenu = () => {
        navMenu.classList.toggle("active");
      };

      menuToggle.addEventListener("click", toggleMenu);

      return () => {
        menuToggle.removeEventListener("click", toggleMenu);
      };
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const events = [
    {
      date: "01.02 - SÃO PAULO/SP",
      event: "SANTUÁRIO MÃE DE DEUS",
      time: "SÁBADO 18:00h | ROSÁRIO DA VITÓRIA"
    },
    {
      date: "10.02 - INTELIGÊNCIA LTDA",
      event: "PODCAST | YOUTUBE",
      time: "SEGUNDA 19:00h - AO VIVO"
    },
    {
      date: "11.02 - PÂNICO JOVEM PAN",
      event: "RÁDIO JOVEM PAN | YOUTUBE E RÁDIO",
      time: "TERÇA 12:00h - AO VIVO"
    },
    {
      date: "12.02 - TICARACATICAST",
      event: "PODCAST | YOUTUBE",
      time: "QUARTA 14:00h - AO VIVO"
    },
    {
      date: "30 e 31 de Agosto - DESPERTA BRASIL 2025",
      event: "BRASÍLIA | ESTÁDIO MANÉ GARRINCHA",
      time: "SÁBADO 15:00h - AO VIVO"
    },
  ];

  const shareUrl = "https://freigilson.com.br"; 
  const title = "Frei Gilson / Som do Monte / Rede Social";

  return (
    <Router>
      <div>
        {/* Menu Toggle (Mobile) */}
        <div className="menu-toggle" id="menu-toggle">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        {/* Menu de Navegação */}
        <nav className="nav-menu" id="nav-menu">
          <ul>
            <li><a href="#sobre">Sobre</a></li>
            <li><a href="#redes">Redes Sociais</a></li>
            <li><a href="#agenda">Agenda</a></li>
            <li><a href="#contato">WhatsApp</a></li>
          </ul>
        </nav>

        {/* Conteúdo Principal */}
        <div className="container">
          <Routes>
            {/* Rota principal (Home) */}
            <Route
              path="/"
              element={
                <>
                  {/* Seção Sobre */}
                  <section id="sobre" className="sobre">
                    <a href="/" onClick={() => window.location.reload()}>
                      <img 
                        src="https://yt3.googleusercontent.com/ytc/AIdro_mpIbyCKdZs57axqDTcmjfCWSM8UZ-gT2F3BVRaAniL90E=s160-c-k-c0x00ffffff-no-rj" 
                        alt="Foto do Frei Gilson" 
                        className="foto" 
                      />
                    </a>
                    <h1>FREI <strong>GILSON</strong> / SOM DO MONTE</h1>
                    <p className="descricao">
                      Frei Gilson é um missionário que, através da música e da pregação, tem impactado a vida de milhares de pessoas, levando a mensagem de Deus de forma inspiradora e transformadora. Sua trajetória é um exemplo de como a fé pode ser um motor de mudança e de como a música e a comunicação podem ser ferramentas poderosas para a evangelização.
                    </p>
                  </section>

                  <YouTubeEmbed channelId={channelId} apiKey={apiKey} />

                  {/* Seção Agenda */}
                  <section id="agenda" className="agenda">
                    <h3>AGENDA</h3>
                    <Slider {...settings}>
                      {events.map((event, index) => (
                        <div key={index} className="agenda-item">
                          <h4>{event.date}</h4>
                          <p><strong>{event.event}</strong> - {event.time}</p>
                        </div>
                      ))}
                    </Slider>
                  </section>

                  {/* Seção Redes Sociais */}
                  <section id="redes" className="redes">
                    <h3>Acompanhe seus canais e deixe-se inspirar por sua mensagem de fé e esperança.</h3>
                    <div className="links">
                      <a href="https://carmelitasmensageiros.org" target="_blank" rel="noopener noreferrer" className="link">
                        <FaGlobe /> <span>Site</span>
                      </a>
                      <a href="https://www.youtube.com/@FreiGilsonSomdoMonteOFICIAL" target="_blank" rel="noopener noreferrer" className="link">
                        <FaYoutube /> <span>YouTube</span>
                      </a>
                      <a href="https://lojamensageiros.com" target="_blank" rel="noopener noreferrer" className="link">
                        <FaShoppingCart /> <span>Loja Mensageiros</span>
                      </a>
                      <a href="https://www.instagram.com/freigilson_somdomonte/" target="_blank" rel="noopener noreferrer" className="link">
                        <FaInstagram /> <span>Instagram</span>
                      </a>
                      <a href="https://www.facebook.com/SomdoMonteOficial" target="_blank" rel="noopener noreferrer" className="link">
                        <FaFacebook /> <span>Facebook</span>
                      </a>
                      <a href="https://www.tiktok.com/@freigilsonoficial" target="_blank" rel="noopener noreferrer" className="link">
                        <FaTiktok /> <span>TikTok</span>
                      </a>
                      <a href="https://twitter.com/FreiGilsonCMES" target="_blank" rel="noopener noreferrer" className="link">
                        <FaXTwitter /> <span>Twitter</span>
                      </a>
                      <a href="https://open.spotify.com/intl-pt/artist/0jzTaWBcJ6h5ccHe04CcyX?si=i9W6s7FaQ0CJLZ6cdOcWVA&nd=1&dlsi=b1766e53846e463f" target="_blank" rel="noopener noreferrer" className="link">
                        <FaSpotify /> <span>Spotify</span>
                      </a>
                    </div>

                    {/* Botões de Compartilhamento */}
                    <div className="social-share-buttons">
                      <h3>Compartilhe esta página:</h3>
                      <WhatsappShareButton url={shareUrl} title={title}>
                        <button>Compartilhar no WhatsApp</button>
                      </WhatsappShareButton>
                    </div>
                  </section>

                  {/* Seção de Contato */}
                  <section id="contato" className="links">
                    <a href="https://whatsapp.com/channel/0029VaPrjiuAojYsKMYtdm1s" 
                       target="_blank" rel="noopener noreferrer" 
                       className="whatsapp-link">
                      <span className="whatsapp-icon"></span> Canal WhatsApp
                    </a>
                  </section>

                  {/* Rodapé */}
                  <footer>
                    <p>© 2025 Frei Gilson</p>
                  </footer>

                  {/* Disclaimer */}
                  <section id="disclaimer">
                    <p className="disclaimer-text">
                      Este site não é oficial e não possui vínculo direto com Frei Gilson. Foi criado para centralizar suas redes sociais e facilitar o acesso às suas plataformas oficiais, conectando você ao seu conteúdo e mensagens.
                    </p>
                  </section>
                </>
              }
            />

            {/* Rota para a página de Liturgia */}
            <Route path="/liturgia" element={<Liturgia />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;