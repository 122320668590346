import React, { useState, useEffect } from 'react';
import axios from 'axios';

const YouTubeEmbed = ({ channelId, apiKey }) => {
  const [videoId, setVideoId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Função para buscar o último vídeo do canal
    const fetchLatestVideo = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            part: 'snippet',
            channelId: channelId,   // Usando o channelId diretamente
            order: 'date',           // Ordena por data (do mais recente)
            type: 'video',           // Garante que estamos buscando vídeos e não playlists
            maxResults: 1,           // Limitando para pegar apenas o mais recente
            key: apiKey,             // Sua chave de API
          },
        });

        // Verifica se há vídeos na resposta
        if (response.data.items.length > 0) {
          const latestVideoId = response.data.items[0].id.videoId;  // ID do vídeo mais recente
          setVideoId(latestVideoId);  // Atualiza o estado com o ID do vídeo mais recente
        } else {
          setError('Nenhum vídeo encontrado no canal.');
        }
      } catch (err) {
        setError('Erro ao carregar o vídeo: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestVideo();  // Chama a função para buscar o vídeo mais recente
  }, [channelId, apiKey]);  // Reexecuta a requisição caso o channelId ou apiKey mude

  // Exibe uma mensagem de carregamento enquanto o vídeo está sendo buscado
  if (loading) {
    return <p>Carregando o vídeo...</p>;
  }

  // Exibe uma mensagem de erro caso algo dê errado
  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="youtube-embed" style={styles.container}>
      {videoId ? (
        <iframe
          style={styles.iframe}
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      ) : (
        <p>Não foi possível obter o vídeo.</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    paddingBottom: '56.25%', // Proporção 16:9 (altura/largura)
    height: 0,
    overflow: 'hidden',
    maxWidth: '95%', // Largura máxima do contêiner
    margin: '0 auto', // Centraliza o contêiner horizontalmente
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%', // Ocupa 100% da altura do contêiner
  },
};

export default YouTubeEmbed;
